export const config = {

  // apiUrl: "https://suliyamapi.technogenesis.in",  //Testing
  // employeeapiURL: "https://suliyamapi.technogenesis.in", //Testing
  // nbfcapiURL: "https://suliyamapi.technogenesis.in", //Testing
  // nbfcemployeeapiURL: "https://suliyamapi.technogenesis.in", //Testing
  // phpApiURL: 'https://suliyambackend.technogenesis.in/api/V1/', //Testing 

  apiUrl: "https://legalapi.demonow.tech", //Production
  employeeapiURL: "https://legalapi.demonow.tech", //Production
  nbfcapiURL: "https://legalapi.demonow.tech",//Production
  nbfcemployeeapiURL: "https://legalapi.demonow.tech",//Production
  phpApiURL: 'https://legalphpapi.demonow.tech/api/V1/',//Production 


  authRoles: {
    sa: ["SA"], // Only Super Admin has access
    admin: ["SA", "Admin"], // Only SA & Admin has access
    editor: ["SA", "Admin", "Editor"], // Only SA & Admin & Editor has access
    user: ["SA", "Admin", "Editor", "User"], // Only SA & Admin & Editor & User has access
    guest: ["SA", "Admin", "Editor", "User", "Guest"], // Everyone has access
  },
};
